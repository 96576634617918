/* Avoid black border on sliders */
.carousel__slide-focus-ring {
  display: none;
}
.carousel__slider {
  touch-action: pan-y;
}

.carousel__dot {
  color: theme('colors.hg.secondary500');
}

.carousel__dot--selected {
  color: theme('colors.hg.secondary');
  transform: scale(150%);
}

.customCarouselAnimation {
  transition: transform 250ms;
  transition-timing-function: ease-in-out;
  will-change: transform;
}

.linedDot.carousel__dot--selected {
  transform: none;
}

.linedDot.carousel__dot--selected > div {
  background: theme('colors.th.blue');
  width: theme(spacing.16);
}
